.addNew-con {
  text-align: left;
  padding: 6.2% 8%;
}
.addNew-head {
  color: rgba(0, 0, 0, 1);
  padding: 0 0 2%;
}
.dropdown-box {
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  border-radius: 5pxs;
  width: 260px;
  height: 41px;
}

#fInput {
  width: 100%;
  height: 49px !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  margin-bottom: 2%;
}
.f2Input-con {
  padding: 0 15px;
}
#f2Input {
  width: 80%;
  height: 49px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
}
#fInput2 {
  width: 100%;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  margin-bottom: 2%;
}
.f2Input-b {
  width: 18%;
  margin-left: 2%;
  height: 49px;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  background: rgba(255, 245, 108, 1);
}
.addNew-subcon2 {
  padding: 1% 0;
}
#f3Input {
  width: 100%;
  /* width: 576px; */
  height: 49px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
}
.addNew-mainB {
  width: 23.42%;
  height: 43px;
  margin: 3% 38.28%;
  height: 49px;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  background: rgba(255, 245, 108, 1);
}

@media (max-width: 480px) {
  .addNew-head {
    font-size: 23px;
    margin: 1em 0.7em;
  }
}

@media (max-width: 767px) {
  .addNew-head {
    padding: 6% 0 2%;
  }
  .f2Input-con {
    padding: 0;
  }
  .addNew-subcon2 {
    padding: 1% 15px;
  }
  .addNew-mainB {
    width: 33.42%;
  }
}
