.AppId-con {
  height: 100%;
  padding: 6%;
  text-align: left;
}

.appId {
  padding: 1% 2%;
  margin: 1% 0;

  font-size: 20px !important;
  font-weight: 500;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
}
.appId-subcon {
  display: flex;
  align-items: center;
  padding: 0 15px;
  /* justify-content: space-between;
  -webkit-box-align: center;
  -webkit-box-pack: justify; */
}
.appId-heading {
  padding: 1% 0 2%;
}
.appId-subcon1,
.appId-subcon2 {
  padding: 1% 0;
}

.appId-subcon4 {
  padding: 2.5% 15px 1%;
}
.appId-liB {
  font-size: 18px;
  width: 239px;
  height: 38px;
  padding: 3px 5px;
  margin: 0 3% 0 0;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  border-radius: 5px;
}
.appId-link1 {
  text-align: center;
  margin: 0 20px 0 0;
  padding: 3px;
  background: #fff56c;
  border-radius: 5px;
  width: fit-content;
}
.appId-link1 p {
  margin: 0 !important;
}

@media (max-width: 480px) {
  .appId {
    font-size: 13px !important;
  }
  .appId-head {
    padding: 0;
  }
  .appl-head {
    font-size: 23px;
  }
  .appId-liB {
    width: 100% !important;
    margin: 1% 3% 0 0 !important;
  }
}
@media (max-width: 767px) {
  .appl-head {
    padding: 6% 2% 2%;
  }
  .row {
    margin: 0 !important;
  }
  .appId {
    margin: 5% 2%;
  }
  /* .appId-heading {
    padding-left: 15px !important;
  } */
  .appId-link1,
  .appId-link2 {
    margin-left: 15px !important;
  }
}
.fa-heart-o {
  color: black;
  cursor: pointer;
}

.fa-heart {
  color: red;
  cursor: pointer;
}
