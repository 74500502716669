.appl-con {
  padding: 8%;
  text-align: left;
}
.Appl-card-subcon {
  display: flex;
  padding: 0;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
}

.Appl-card-subco {
  display: flex;
  width: 40%;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
}

@media (max-width: 480px) {
  .Appl-card-subco {
    padding-left: 15px !important;
  }
  .Pdetails {
    padding-top: 1.5em;
  }
  .Heart-toggle {
    margin: 5px 0 5px 15px;
  }
}
.Appl-Icon {
  margin-left: auto;
}
.appl-subcon1 {
  display: flex;
  align-items: center;
  /* justify-content: space-between;
  -webkit-box-align: center;
  -webkit-box-pack: justify; */
  /* align-items: center;
  justify-content: space-between; */
}
.appl-head {
  padding: 0 0 2%;
}
.AppIcon {
  margin-left: auto;
  font-size: 20px !important;
  padding: 0 10px;
}
.Appl-card {
  padding: 1% 2%;
  margin: 1% 0;

  font-size: 20px !important;
  font-weight: 500;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
}
.Appl-card-heading {
  padding: 1% 0;
}
.Appl-card-heading2 {
  padding: 1% 0;
  font-size: 16px;
}
.Appl-card-subcon1,
.Appl-card-subcon2 {
  padding: 1% 0;
}
.Appl-card-link1 {
  text-align: center;
  margin: 0 20px 0 0;
  padding: 3px;
  background: #fff56c;
  border-radius: 5px;
  width: fit-content;
}
.Appl-card-link1 p {
  margin: 0 !important;
}
@media (max-width: 767px) {
  .appl-head {
    padding: 6% 0 2%;
  }
  .row {
    margin: 0 !important;
  }
  .Appl-card {
    margin: 5% 2%;
  }
  .Appl-card-heading {
  }
  .Appl-card-subcon {
    flex-direction: column;
  }
  .Appl-card-link1,
  .Appl-card-link2 {
    margin-left: 15px !important;
    margin-bottom: 5px;
  }
}
